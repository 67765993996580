.signInContainer {
  display: flex;
  flex-direction: column;
  padding: 0% 35%;
  width: 100%;
}

.signInHeading {
  /* font-size: 2em !important; */
  text-transform: uppercase;
  text-align: center;
}

.signInControl {
  border-radius: 9px;
  font-size: 14px !important;
  flex-grow: 1;
  text-align: start;
  padding: 0rem 0.5rem;
  font-family: inherit;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  width: 100%;
  box-shadow: inset 0 0 0 0.1rem #0000000d;
  height: 3rem;
  box-sizing: border-box;
  transition: box-shadow ease;
  line-height: calc(1 + 0.5);
  letter-spacing: 0.04rem;
}

.signUpText {
  font-size: 20px !important;
  line-height: 32px;
  text-align: center;
}

.signUPMessage {
  text-align: center;
  display: flex;
  /* background-color: gray; */
  justify-content: center;
  align-items: center;
  color: red;
  /* padding: 2% 0%;
  margin-top: 5%; */
}

@media (max-width: 768px) {
  .signInContainer {
    padding: 0% 3%;
  }
}

.signInGoogle {
  /* iframe { */
  /* display: none; */
  /* } */
}