.card-container {
  width: 100%;
  /* padding: 20px 34px; */
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .VSVsP {
    margin: 0px !important;

  }
}

.single-card {
  /* width: 100%; */
  border-radius: 10px;
  margin-bottom: 5px;
  border: 1px solid #eeeeee;
  /* padding: 5%; */
  /* background-color: #f1eef6; */
  background-color: var(--cardBackground);
}

.product-img {
  min-height: 30dvh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.productImage {
  /* background: var(--cardBackground); */
  opacity: 1;
  display: block;
  width: 100%;
  /* height: 359px; */
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 10px 10px 0px 0px;
}

.product-desc-container {
  font-weight: 500;
  font-size: 15px;
  /* padding: 12px; */
}

/* .price {
  float: right;
}

.product-desc a {
  text-decoration: none;
  color: black;
}

.product-img {
  position: relative;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.product-img:hover .productImage {
  /* opacity: 0.7; */
/* } */

.single-card:hover .middle {
  /* opacity: 1; */
}

.single-card:hover {
  /* background-color: #f1eef6; */
}

.arrow {
  margin-left: 4px;
}

.text {
  width: 100px;
  height: 30px;
  left: 97px;
  font-size: 12px;
  line-height: 28px;
  padding: 0;
  font-weight: 600;
  bottom: 48px;
  border: 1px solid var(--color);
  color: var(--color);
  background: rgba(255, 255, 255, 0.9);
  text-shadow: none;
}

s {
  color: gray;
}

.product-name {
  font-weight: 600;
  font-size: 13px;
  color: #333;
  /* min-height: 72px; */
  width: 100%;
  font-size: 13px;
  line-height: 26px;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 0.2rem;
  letter-spacing: calc(1 * 0.03rem);
  padding-top: 1.2rem;
  letter-spacing: calc(1 * 0.06rem);
  padding-top: 0.5rem;
  display: flex;
  /* justify-content: center; */
  text-align: left;
  padding-left: 2%;
  /* display: flex!important; */
}

.price {
  color: var(--primary);
  font-weight: 600;
  font-size: 11px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 2%;
}

.addbtn {
  width: 100%;
  background-color: var(--primary);
  border: none;
  color: white;
  cursor: pointer;
  background: var(--primary);
  border-color: var(--primary);
  line-height: 20px;
  border-radius: unset !important;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-family: Outfit;
  min-height: 40px !important;
  pointer-events: visible;
  line-height: calc(1 + 0.8 / 1);
  margin-top: 2.5%;
  text-transform: uppercase;
}

.disabled {
  opacity: 0.3;
}

.checkOutBtn {
  font-size: 18px;
  letter-spacing: 0.2rem;
}

.new-price {
  width: 50%;
  color: #149253 !important;
  line-height: 1;
  font-size: calc(var(1) * 1.8rem);
  font-size: 16px !important;
  padding-left: 5px;
}

.oldPrice {
  width: 50%;
  font-weight: 300;
  line-height: 1;
  font-size: 14px !important;
  color: #969696 !important;
}

.priceContainer {
  display: flex;
  margin-top: 0.6rem;
}

.single-card a {
  text-decoration: none;
  color: var(--black);
}

.captcha-divcontainer {
  display: flex;
}

.captcha-div {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  width: 100%;
}

.half-width {
  width: 45%;
}

.space {
  height: 32px;
  width: 100%;
}

/* media query for tab  */
@media (max-width: 768px) {
  .productListContainer {
    padding: 0 !important;
  }




  .product-img {
    min-height: 20dvh;
  }

  .product-container {
    padding: 0;
  }

  /* .sc-gzVnrw.jVJmF {
    width: 50%;
  } */
  .half-width {
    width: 100%;
  }

  .flex-row {
    flex-direction: column;
  }

  .wrap {
    margin-bottom: 0px;
  }

  .half-width {
    margin: 6px 0px;
  }

  .productImage {
    height: auto;
    /* height: fit-content; */
  }
}

/* media query for phone  */

@media (max-width: 480px) {


  .card-container {
    padding: 0px;
  }
}