@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

body {
  margin: 0;
  padding: 0% 10%;
  font-family: Outfit, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100dvh;
  /* background-color: whitesmoke; */
}

#root>.container {
  max-width: 1440px;
  margin: 0 auto;
}