.slider-img {
  /* height:409px; */
  height: 60dvh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-color: whitesmoke;
  /* border-radius: 21px; */
}

.slider-containerDiv {
  /* width: 92%;
    margin: 13px 4%; */
  width: 100%;
}

@media (max-width: 768px) {
  .slider-img {
    height: 30dvh;
    /* background-size: contain; */
  }
}

@media (max-width: 480px) {}

/* # Tablet */
/* only screen and (min-width: 768px)  */