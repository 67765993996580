.productdetail-container {
  display: flex;
  /* padding: 2% 4%; */
  justify-content: space-between;
}

.productdetails-left-container {
  /* flex: 1; */
  width: 30%;
}

.productdetails-right-container {
  /* flex: 1; */
  width: 66%;
}

.product-img-container {
  display: flex;
}

.big-product-img {
  background-color: #F5F5F5;
  border-radius: 2%;
  display: flex;
  justify-content: center;
}

.big-product-img img {
  width: 100%;
}

@media (max-width: 768px) {
  .big-product-img img {
    width: inherit;
  }
}

.button-flex {
  display: flex;
}

.top-productmenu span {
  border-radius: 3px;
  padding: 1px 6px;
  border: 1px solid #bfb4b4;
  margin: 5px;
  color: #5c5353;
}

.productFeatures {
  display: flex;
  /* border-top: 1px solid rgb(240, 236, 236); */
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin-top: 5%;
  padding-top: 2%;
}

.saveUpto {
  font-weight: 100;
  float: left;
  margin-top: 1%;
  color: white;
  background-color: #9ca3af;
  padding: 0.2% 2%;
  width: 25%;
  border-radius: 10px;
}

.product-names {
  word-break: break-word;
  font-weight: bold;
  font-size: 30px;
  /* font-size: 30px; */
  font-family: Outfit;
  font-weight: 500;
  line-height: 46px;
  font-style: normal;
  letter-spacing: 0.06rem;
}

.product_subtitle {
  padding: 5px 0px 10px 0px;
  font-size: 12px;
  color: #555;
}

.quantity_container {
  display: flex;

  justify-content: center;
  align-items: center;
}

.quantity_btn {
  padding: 4px 14px;
  color: #666;
  border: 1px solid #aaa;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.quantity_count {
  border-left: none;
  border-right: none;
}

.price-btn-container {
  /* padding-top: 3%; */
}

.aboutProductHeading {
  font-size: 24px !important;
  line-height: 32px;
  justify-content: start;
  margin-top: 3%;
  letter-spacing: -0.02em;
  font-weight: 400;
}

.aboutProductDescription {
  /* max-width: 692px; */
  margin: 0 auto;
  font-size: 18px;
  line-height: 30px;
  color: #646464;
  font-weight: 300;
  font-family: Outfit;
  text-align: justify;
}

/* //media querys */
/* @media (max-width: 480px) */
@media (max-width: 768px) {
  .saveUpto {
    width: 50%;
    margin-top: 0.6rem;
    margin-left: 3%;
  }

  .product-names {
    font-size: 20px;
  }

  .aboutProductDescription {
    max-width: 692px;
  }

  .productdetail-container {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .productdetails-left-container {
    width: 100%;
  }

  .productdetails-right-container {
    /* padding: 0% 4%; */
    width: 100%;
  }

  .quantity_container {
    justify-content: left;
  }
}