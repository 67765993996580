.productListContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 1rem;
  /* justify-content: space-between; */
  padding: 0% 4%;

  .simpleCard {
    width: 18.8%;
  }

  @media (max-width: 768px) {
    .simpleCard {
      width: 47.5%;
      margin-bottom: 17px;
    }
  }
}

.filter {
  padding: 1% 4%;
}

@media (max-width: 768px) {
  .filter {
    padding: 4% 1%;
  }
}