.productContainer {
  display: flex;
  margin: 1% 4%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9cece;
  font-size: 12px;
}

.addresses {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  transition: 0.3s ease;
  /* justify-content: space-between; */
}

.addresses>div {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  /* width: 100%; */
  flex-grow: 1;
}

.orderDetails,
.addressDetails {
  display: flex;
  margin: 1% 4%;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #d9cece; */
}

.addressDetails {
  justify-content: center !important;
  width: 100%;
  gap: 20px;
}

.productImageSection {
  width: 45%;
}

.orderPrice {
  width: 12%;
}

.quantity {
  width: 12%;
}

.total {
  width: 12%;
}

.orderMsg {
  font-size: 12px;
  text-align: center;
  color: #b2beb5;
}

.font12 {
  font-size: 12px;
}

.displayNone {
  position: absolute;
  /* width: 100%; background: pink; padding: 20px;   */
  /* visibility: hidden; */
  display: none;
  height: 0;

  /* transform: translateY(0); */
  /* transition: all 1s ease-out; */
}

.displayBlock {
  animation: slideDown 1s;
  padding: 1% 0% 0% 0%;
  margin: 1% 4% 0% 4%;
  background-color: whitesmoke;
  box-shadow: 0 0 15px rgb(0 0 0 / 12%);
  border-radius: 10px;

}

#editAddressBox {
  resize: none;
  border: none;
  outline: none;
}

.cancelOrder {
  background: #FF0800;
  color: white;
  line-height: 2rem;
  text-align: center;
  border-radius: 0px 0px 10px 10px;

}

/* manageaddress component  start */
.flex-child {
  width: 45%;
}

.address-txt-area {
  width: 100%;
}

.txt-area {
  height: 101px;
}

.cancel {
  flex-direction: column;
  display: flex;
  width: 100%;
  /* margin: 1% 4%; */
  justify-content: space-between;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.saveBtn {
  width: 100%;
  background-color: var(--primary);
  border: none;
  color: white;
  cursor: pointer;
  background: var(--primary);
  border-color: var(--primary);
  line-height: 20px;
  border-radius: unset !important;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-family: Outfit;
  min-height: 40px !important;
  pointer-events: visible;
  line-height: calc(1 + 0.8 / 1);
  margin-top: 25px !important;
  text-transform: uppercase;
}

.cancelBtn {
  width: 100%;
  background-color: gray;
  border: none;
  color: white;
  cursor: pointer;
  /* background: var(--primary); */
  border-color: var(--primary);
  line-height: 20px;
  border-radius: unset !important;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-family: Outfit;
  min-height: 40px !important;
  pointer-events: visible;
  line-height: calc(1 + 0.8 / 1);
  margin-top: 25px !important;
  text-transform: uppercase;
}

/* end  */

@keyframes slideDown {
  from {
    /* height: 0px; */
    opacity: 0;
    visibility: hidden;
  }

  to {
    /* height: auto; */
    opacity: 1;
    visibility: visible;
  }
}

.orderContainer,
.addressContainer {
  box-shadow: 0 0 1px;
  padding: 0.5% 0%;
  /* box-shadow: 0 0 15px rgb(0 0 0 / 18%); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  line-height: 1.8em;
  border-radius: 10px;
  margin: 1.5% 4%;
}

.selectedAddress {
  background-color: var(--primary);
  color: white;
}

.addressContainer {
  margin: 0% !important;
  padding: 2% 0%;
  transition: 0.3s ease;

  /* .fa-plus {
    margin-right: 2%;
  } */
}

.status {
  font-size: 1.3rem;
  cursor: pointer;
}

@media (max-width: 768px) {

  .orderContainer,
  .addressContainer {
    padding: 3% 0%;
    margin: 3% 4%;
  }
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  text-transform: capitalize;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 0.9rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  font-size: 0.9rem;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  /* font-size: 1rem; */
  font-size: 0.8rem;
  color: #9b9b9b;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  /* border-image: linear-gradient(to right, #11998e, #38ef7d); */
  border-image-slice: 1;
}

.form__field:focus~.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.8rem;
  color: #166534;
  /* color: #11998e; */
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

/* demo */