* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Outfit, sans-serif;
}

.container {
  position: relative;
  min-height: 100dvh;
}

.spacing {
  padding-top: calc(36px * 0.75) !important;
}

:root {
  --primary: #3c8031 !important;
  /* --primary: #149253; */
  --secondary: white;
  --black: black;
  --color: #7755a2;
  --cardBackground: #f3f3f3;
  --bodybg: white;
  --containerPadding: 0 4%;
}

.bottom-div {
  margin-bottom: 70px;
}

.searchbox-container>div {
  width: 100%;
}

.comonbtn {
  background-color: var(--primary);
  color: var(--secondary);
  border: none;
  padding: 6px 12px;
}

.form-label {
  text-transform: uppercase;
  font-size: 12px;
  padding: 4px 0 0 0;
  color: var(--black);
}

.form-textbox {
  width: 96%;
  border: none;
  outline: none;
  padding: 6px 0 5px 16px;
  border-bottom: 1px solid #eee;
}

.bottom-menu {
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--primary);
  height: 74px;
}

.react-carousel-responsive__root {
  z-index: 0 !important;
}

.search-icon {
  font-size: 17px;
  z-index: 9999;
  color: var(--black);
  /* text-align: center; */
  /* color: var(--primary); */
  /* background: #012150; */
  /* background-color: var(--secondary); */
  /* padding: 10px; */
  margin-right: -37px;
}

.right-container {
  flex: 0.5;
  display: flex;
  justify-content: center;
}

.left-container {
  display: flex;
  /* flex: .5; */
  flex: 1;
  justify-content: space-evenly;
  /* margin-left: 98px; */
  align-items: center;
}

.searchbox-container {
  width: 96%;
  display: flex;
}

.topheadding {
  margin: 14px 0px;
}

/* .signupBtn{
    background: #fff;
    padding: 7px;
    color: #364230;
    margin: 6px;
    border: 2px solid #b8d3a8;
    border-radius: 3px;
} */
.product-headding {
  color: var(--black);
  line-height: 32px;
  font-size: 24px;
  /* font-size: 36px; */
  line-height: 45px;
  letter-spacing: -0.02em;
  font-weight: 400;
  font-family: Outfit, sans-serif !important;
}

.cart_headding {
  font-size: 20px;
  font-weight: normal;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  text-align: center;
}

.product-container {
  padding: var(--containerPadding);

  .card-container div {
    margin: 0 !important;
  }
}

.simpleCard {
  width: 18%;
}

.simpleCard a {
  text-decoration: none;
}

.footer {
  position: absolute;
  bottom: -100px;
  /* margin-top: 5%; */
  /* right: 0;
  left: 0; */
  width: 100%;
  background-color: var(--primary);
  color: var(--secondary);
}

.footer-content {
  padding: 10px;
  text-align: center;
}

.top-bar {
  width: 100%;
  background-color: var(--primary);
  height: 30px;
  color: var(--secondary);
  display: flex;
  padding: 4px 74px;

  /* justify-content: center; */
  font-size: 13px;
}

.contacts {
  flex: 0.9;
}

.submenu {
  padding: 0px 5px;
}

.sidenav {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Stay on top */
  top: 0;
  background-color: #111;
  /* Black*/
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover,
.offcanvas a:focus {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

.sidenav {
  right: 0;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.sidenav {
  right: 0;
}

.fixedIcons {
  position: fixed;
  bottom: 5%;
  right: 25px;
  z-index: 1000;
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 5%;


  a {
    text-decoration: none;
    color: inherit;
  }

  i {
    font-size: 2.5rem;
  }

  .button {
    color: var(--secondary);
    background-color: var(--primary);
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 5px;
    width: 100%;

  }
}

.cartButton {

  color: var(--primary);
  border: 1px solid;
  border-radius: 19px;
  padding: 1%;
  background-color: white;
  box-shadow: 0 0 10px;
}

.cartButton>i {
  font-size: 3.5rem;
}

.addtocart {
  color: var(--secondary);
  background-color: var(--primary);
  padding: 9px 13px;
  width: 103px;
  height: 35px;
  border: none;
  /* margin: 0px 10px;
  padding: 7px; */
  /* border-radius: 4px; */
}

.buynow {
  background: #111111;
}

.logo-container {
  width: 11%;
}

.tableheadding_tr {
  border-bottom: 1px solid #e5e5e5;
  color: #555;
}

.tableheadding_tr th {
  font-size: 13px;
  font-weight: 600;
  padding: 20px;
}

.cartimg_td {
  width: 201px;
  aspect-ratio: auto 201 / 134;
  height: 134px;
}

.cartimg_td img {
  width: 100%;
}

.cart_product_det {
  padding: 20px;
  font-size: 16px;
  color: #767676;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 32px;
}

.main-txt {
  color: var(--primary);
}

.cart-headding {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  font-size: 13px;
  font-weight: 600;
  padding: 20px;
}

.body-tr {
  /* border-bottom: 1px solid #e5e5e5;   */
  display: flex;
  /* justify-content: center; */
  justify-content: space-between;
  align-items: center;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
}

.product-det-cart div {
  padding-top: 3%;
  letter-spacing: 0.1rem;
}

.cart-container {
  padding: 4%;
}

.cart-body {}

.cart-th {
  /* width: 17%; */
  margin: 4px 16px;
}

.img-cart {
  width: 127px;
}

.product-cart-img {
  width: 100px;
}

.product-det-cart {
  display: flex;
  width: 53%;
  justify-content: space-between;
}


/* Popup style */
.react-responsive-modal-modal.customModal {
  max-width: 400px;
  box-shadow: 0 0 15px rgb(0 0 0 / 18%);
}

.customOverlay {
  background: none;
}

.headding {
  text-align: center;
  /* color: var(--primary); */
}

.left-side {
  width: 20%;
}

.right-side {
  width: 10%;
}

.row {
  margin: 30px 17px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.center-side {
  display: flex;
  width: 60%;
  flex-direction: column;
}

.single-row {
  color: #333;
  text-decoration: none;
}

.sub-tittle {
  font-size: 12px;
  color: #828282;
  font-weight: 500;
}

.title {
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
  font-size: 17px;
}

.icons {
  font-size: 28px;
}

.logincontainer {
  /* box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; */
  /* background-color: aqua; */
  /* margin: 4px 381px;
    padding: 30px 19px; */
}

.form-content {
  width: 50%;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 2%;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap {
  margin-bottom: 12px;
}

.loginbtn {
  display: flex;
  justify-content: center;
}

.headding-container {
  display: flex;
  align-items: center;
  /* margin-left: 19px; */
}

.label-img {
  max-width: 30px;
  margin-right: 9px;
}

.label-img img {
  width: 100%;
}

.contactUSContainer {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 100px;
  margin: auto;
}

.privacyContainer {
  padding: 1% 4%;
  line-height: 2.5;
}

.headingRefund {
  font-weight: bold;
  font-size: x-large;
}

/* media query  */
/* # Mobile */
/* @media (max-width: 480px)  */
@media (max-width: 768px) {
  .fixedIcons {
    width: 15%;
  }

  body {
    padding: 0 3%;
  }

  .cartButton {
    padding: 2%;
  }


  /* //second slidebar */
  .right-container,
  .logo-container,
  .top-bar {
    display: none;
  }

  .img-cart {
    width: 35%;
  }

  .product-det-cart {
    flex-direction: column;
    width: 60%;
  }

  .cart-th {
    margin: 0px 0px;
    padding: 0px 0px;
  }

  .menu {
    font-size: 12px;
  }

  .logincontainer {
    margin: 0;
    box-shadow: none;
  }

  .form-content {
    width: 89%;
    box-shadow: none;
  }


}

/* examples/custom-styling.css */
/* .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
  .customModal {
    background: #b2dbbf;
    max-width: 500px;
    width: 100%;
  } */
#react-sidebar .sidebar-main.show {
  overflow: hidden;
  text-transform: uppercase;
}

.sidebar-body>ul>li {
  color: #000 !important;
  font-family: Outfit, sans-serif !important;
  font-weight: 500;
  font-size: 14px !important;
  letter-spacing: 0.12em;
  padding: 8% 0 !important;
  margin-left: 16px;
  text-transform: uppercase;
  margin-right: 5%;
  border-bottom: 0.1rem solid rgb(210, 213, 217);
}

.sidebar-body {
  background-color: rgb(var(--color-background));
}

.footer {
  background-color: #166534;
  text-align: center;
  /* margin-top: 5%; */
  padding: 1% 0%;
  font-size: 0.8rem;
  margin-bottom: 0px;
}

.hr {
  border-top: 1px solid #e5e7eb;
  margin: 4% 0% 1% 0%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


/* #react-sidebar .sidebar-main {
  width: 80%;
  color: #173a1d;
}
#react-sidebar .sidebar-main.second{
  width: 100%;
} */
.categoryItem>a {
  text-decoration: none;
}

.emptyCartContainer {
  display: flex;
  justify-content: center;
  padding: 5% 0%;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-between;
  row-gap: 10px;
}

.categoryFilterContainer {
  padding: var(--containerPadding);
}

.categoryItem {
  width: 22%;
  text-align: center;
}

.categoryImage img {
  width: 100%;
  border-radius: 10px;
}

.categoryName {
  font-weight: 600;
  font-size: 13px;
  color: #333;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 26px;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 0.2rem;
  letter-spacing: calc(1 * 0.06rem);
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}

.displayNone {
  display: none;
}

.addressContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  /* padding: 3% 0%; */

  .addressItem {
    flex-grow: 1;
    margin-top: 3%;
    flex-basis: 45%;

    .fullNameText {
      .redStar {
        content: "*";
      }
    }

    .inputText {
      padding: 2%;
      margin-top: 2%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .headding-container {
    padding: 0;
  }

  .addressItem {
    flex-basis: 100% !important;
  }

  .product-headding {
    font-size: 24px !important;
    line-height: 32px;
  }



  .categories {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;
    row-gap: 10px;
    /* flex-basis: 25%; */
  }

  .categoryFilterContainer {
    padding: 0% 2%;
  }

  .categoryItem {
    width: 48%;
    text-align: center;
  }


  .categoryName {
    font-weight: 600;
    font-size: 13px;
    color: #333;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 26px;
    color: #000;
    font-weight: 500;
    text-decoration: none;
    padding-bottom: 0.2rem;
    letter-spacing: calc(1 * 0.06rem);
    padding-top: 0.2rem;
    display: flex;
    justify-content: center;
  }
}